import React from 'react';
import { graphql } from 'gatsby';
import Theme from '../components/PageCommon/Theming/ThemeProvider';
import PageContainer from '../components/PageCommon/PageContainer/PageContainer';
import PageMeta from '../components/PageCommon/PageMeta/PageMeta';
import Footer from '../components/PageCommon/Footer/Footer';
import MainContainer from '../components/PageCommon/MainContainer/MainContainer';
import NavBar from '../components/PageCommon/NavBar/NavBar';
import { Grid } from '@material-ui/core';
import ListedPostCard from '../components/Cards/ListedPostCard/ListedPostCard';
import PageTitleCard from '../components/Cards/PageTitleCard/PageTitleCard';

import useSiteMetadata from '../hooks/useSiteMetadata';

const CategoryPostsTemplate = ({
    data: {
        allMarkdownRemark: { nodes },
    },
    pageContext: { category },
}) => {
    const categoryPosts = nodes.map((item) => ({
        img: item.frontmatter.heroImage.childImageSharp.fluid,
        title: item.frontmatter.title,
        text: item.excerpt_short,
        date: item.frontmatter.date,
        linkText: 'Lasīt tālāk...',
        linkHref: item.fields.slug,
        themes: item.frontmatter.themes,
    }));

    const { siteUrl, categoryMap } = useSiteMetadata();
    const url = siteUrl + '/' + category + '/all';
    const pageDescription = "All category '" + category + "' posts.";
    const pageTitle = pageDescription;
    const pageLabel = categoryMap[category];

    return (
        <Theme>
            <PageMeta title={pageTitle} pageDescription={pageDescription} url={url} />
            <PageContainer>
                <NavBar page={category} />
                <MainContainer>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ width: '100%' }}>
                            <PageTitleCard label="VISI RAKSTI: " linkText={pageLabel} />
                        </Grid>
                        {categoryPosts.map((el, idx) => (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={idx} style={{ width: '100%' }}>
                                <ListedPostCard
                                    title={el.title}
                                    date={el.date}
                                    img={el.img}
                                    text={el.text}
                                    linkText={el.linkText}
                                    linkHref={el.linkHref}
                                    themes={el.themes}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </MainContainer>
                <Footer />
            </PageContainer>
        </Theme>
    );
};

export default CategoryPostsTemplate;

export const queryData = graphql`
    query($category: [String]) {
        allMarkdownRemark(
            sort: { order: DESC, fields: frontmatter___date }
            filter: { frontmatter: { category: { in: $category } } }
        ) {
            nodes {
                excerpt_short: excerpt(pruneLength: 150)
                frontmatter {
                    date(formatString: "DD MMM, YYYY")
                    title
                    category
                    themes
                    heroImage {
                        childImageSharp {
                            ...smImageFluid
                        }
                    }
                }
                fields {
                    slug
                }
            }
        }
    }
`;
